<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="'Thông tin chương trình tính hoa hồng ngành hàng phụ kiện'"
        >
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="8">
                        <b-form-group
                          id="input-group-name"
                          label="Tên chương trình"
                          label-for="input-name"
                          label-align-md="left"
                          class="required-control"
                        >
                          <b-form-input
                            size="sm"
                            id="input-name"
                            v-model="$v.mainModel.name.$model"
                            placeholder="Nhập tên"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Mô tả">
                          <b-form-textarea
                            id="input-description"
                            v-model="mainModel.description"
                            placeholder="Nhập mô tả"
                            rows="5"
                            :max-length="255"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group
                          id="input-group-start-date"
                          label-align-md="left"
                          required
                          class="required-control"
                          label="Từ ngày"
                        >
                          <!-- <label for="input-start-date">Ngày bắt đầu:</label> -->
                          <date-picker
                            placeholder="Từ ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="mainModel.startAt"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group id="input-group-end-date">
                          <label for="input-end-date">Đến ngày</label>
                          <date-picker
                            placeholder="Đến ngày"
                            class="form-control form-control-sm"
                            :config="dpConfigs.date"
                            v-model="mainModel.endAt"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group id="input-3" label="Trạng thái">
                          <b-form-select
                            size="sm"
                            id="input-3"
                            v-model="mainModel.status"
                            :options="listStatus"
                            required
                            value-field="id"
                            text-field="name"
                            disabled-field="notEnabled"
                          ></b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="6">
                        <b-form-group id="input-3" label="Gói thưởng(%):">
                          <b-form-input
                            size="sm"
                            id="input-rewardPackageTarget"
                            type="text"
                            v-model="mainModel.rewardPackageTarget"
                            placeholder="Nhập gói thưởng"
                            class="text-right"
                            v-mask="mask"
                          ></b-form-input>
                          <span style="color: #d3d3d3"
                            >% trên tổng lãi gộp của ngành hàng</span
                          >
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <hr class="hr-text" data-content="" style="font-weight: 600" />
                <h4>Điều kiện đạt gói thưởng</h4>
                <b-row>
                  <b-col md="6">
                    <b-form-group id="input-3" label="1) Lãi gộp(% doanh thu):">
                      <b-form-input
                        size="sm"
                        id="input-rewardPackageTarget"
                        type="text"
                        v-model="mainModel.grossProfitTarget"
                        placeholder="Nhập mục tiêu lãi gộp"
                        class="text-right col-6"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="input-3"
                      label="2) Doanh thu(VND):"
                      class="col-md-6 pl-0"
                    >
                      <b-form-input
                        size="sm"
                        id="input-rewardPackageTarget"
                        v-model="mainModel.incomeTarget"
                        placeholder="Nhập mục tiêu doanh thu"
                        class="text-right"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <span>3) Tồn kho( tổng [a] + [b]) (%)</span>
                    <b-row class="mt-4">
                      <b-col md="10">
                        <b-row
                          class="row-center-vertical pl-10"
                          style="justify-content: left"
                        >
                          <span
                            >a. Giá trị tồn kho/ Doanh thu thực bán &lt;=
                          </span>
                          <b-form-input
                            size="sm"
                            id="input-rewardPackageTarget"
                            type="text"
                            v-model="mainModel.inventoryValueTarget"
                            placeholder="Nhập mục tiêu giá trị tồn kho"
                            class="text-right ml-4 mr-4 col-3"
                            v-mask="mask"
                          ></b-form-input>
                          <span> ( lần)</span>
                        </b-row>
                      </b-col>
                      <b-col md="2">
                        <b-row
                          class="row-center-vertical"
                          style="
                            padding-left: inherit;
                            padding-right: inherit;
                            justify-content: center;
                          "
                        >
                          <span> =&gt; Đạt: </span>
                          <b-form-input
                            size="sm"
                            id="input-rewardPackageTarget"
                            type="text"
                            v-model="mainModel.inventoryValuePoint"
                            placeholder="Nhập điểm"
                            class="text-right ml-4 mr-4 col-3"
                            v-mask="mask"
                          ></b-form-input>
                          <span> ( %)</span>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col md="10">
                        <b-row class="pl-10">
                          <span>b. Thời gian tồn kho</span>
                        </b-row>
                      </b-col>
                      <b-col md="2"> </b-col>
                    </b-row>
                    <b-row class="mt-4">
                      <b-col md="10">
                        <b-row class="pl-10">
                          <button
                            @click="onAddCondition"
                            type="button"
                            class="btn btn-primary font-weight-bolder btn-sm"
                          >
                            <i
                              style="font-size: 1rem"
                              class="flaticon2-add-1"
                            ></i
                            >Thêm điều kiện
                          </button>
                        </b-row>
                      </b-col>
                      <b-col md="2">
                        <b-row
                          class="row-center-vertical"
                          style="
                            padding-left: inherit;
                            padding-right: inherit;
                            justify-content: center;
                          "
                        >
                          <span> =&gt; Đạt: </span>
                          <b-form-input
                            size="sm"
                            id="input-rewardPackageTarget"
                            type="text"
                            v-model="mainModel.inventoryTimePoint"
                            placeholder="Nhập điểm"
                            class="text-right ml-4 mr-4 col-3"
                            v-mask="mask"
                          ></b-form-input>
                          <span> ( %)</span>
                        </b-row>
                      </b-col>
                    </b-row>
                    <b-row class="mt-4 pl-10 pr-10">
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fieldInventoryTimeTarget"
                        :items="mainModel.listInventoryTimeTarget"
                        v-if="renderComponent"
                      >
                        <template v-slot:cell(priority)="row">
                          <b-form-input
                            size="sm"
                            id="input-rewardPackageTarget"
                            type="number"
                            v-model="row.item.priority"
                            placeholder="Nhập thứ tự"
                            class="text-right"
                          ></b-form-input>
                        </template>
                        <template v-slot:cell(fromMonth)="row">
                          <b-form-input
                            size="sm"
                            id="input-rewardPackageTarget"
                            type="number"
                            v-model="row.item.fromMonth"
                            placeholder="Nhập tháng"
                            class="text-right"
                          ></b-form-input>
                        </template>
                        <template v-slot:cell(toMonth)="row">
                          <b-form-input
                            size="sm"
                            id="input-rewardPackageTarget"
                            type="number"
                            v-model="row.item.toMonth"
                            placeholder="Nhập tháng"
                            class="text-right"
                          ></b-form-input>
                        </template>
                        <template v-slot:cell(isCheckInventoryValue)="row">
                          <b-form-checkbox
                            v-model="row.item.isCheckInventoryValue"
                            size="lg"
                          ></b-form-checkbox>
                        </template>
                        <template v-slot:cell(rateOnIncome)="row">
                          <b-form-input
                            size="sm"
                            id="input-rewardPackageTarget"
                            v-model="row.item.rateOnIncome"
                            placeholder="Nhập tỷ lệ DTTB"
                            class="text-right"
                            v-mask="mask"
                          ></b-form-input>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <v-icon
                            small
                            class="text-danger text-center"
                            @click="deleteInventoryTimeTarget(row.item)"
                            v-b-tooltip
                            title="Xóa"
                            >mdi-delete</v-icon
                          >
                        </template>
                      </b-table>
                    </b-row>
                    <span
                      >* Lãi gộp = Doanh thu thực bán - ( Giá nhập + quà tặng +
                      chi phí Marketing + Hoa hồng)</span
                    >
                    <br />
                    <span>
                      * Phần trăm đạt được trên gói thường: = [(1) + (2) + (3)]
                      / 3</span
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6"> </b-col>
                </b-row>
                <hr class="hr-text" data-content="" style="font-weight: 600" />
                <h4 class="mt-4 mb-4">Chi tiết hoa hồng</h4>
                <b-row>
                  <b-col md="6">
                    <b-form-group id="input-group-search">
                      <label>Giám đốc ngành hàng</label>
                      <vue-autosuggest
                        class="col-md-6 pl-0"
                        v-model="searchDirector"
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedDirector"
                        :limit="10"
                        @input="onInputChangeDirector"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập tên giám đốc ngành hàng',
                          style: 'border-radius:0px!important',
                        }"
                      >
                        <template slot-scope="{ suggestion }">
                          <div>{{ suggestion.item.name }}</div>
                        </template>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="input-3"
                      label="Tỷ lệ thưởng giám đốc(%):"
                    >
                      <b-form-input
                        size="sm"
                        id="input-rewardPackageTarget"
                        type="text"
                        v-model="mainModel.directorRewardRate"
                        placeholder="Nhập tỷ lệ thưởng giám đốc"
                        class="text-right col-6"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group id="input-group-search">
                      <label>Admin Giám đốc ngành hàng</label>
                      <vue-autosuggest
                        class="col-md-6 pl-0"
                        v-model="searchDirectorAdmin"
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedDirectorAdmin"
                        :limit="10"
                        @input="onInputChangeDirectorAdmin"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập tên Admin giám đốc ngành hàng',
                          style: 'border-radius:0px!important',
                        }"
                      >
                        <template slot-scope="{ suggestion }">
                          <div>{{ suggestion.item.name }}</div>
                        </template>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Tỷ lệ thưởng Admin giám đốc(%):"
                    >
                      <b-form-input
                        size="sm"
                        type="text"
                        v-model="mainModel.directorAdminRewardRate"
                        placeholder="Nhập tỷ lệ thưởng Admin giám đốc"
                        class="text-right col-6"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-form-group id="input-group-search" class="col-md-12">
                    <label for="input-code">Tìm nhóm sản phẩm:</label>
                    <b-col md="12" class="row pr-0">
                      <b-col md="6" class="p-0">
                        <vue-autosuggest
                          v-model="searchProductFor"
                          :suggestions="filteredOptionsProductFor"
                          @selected="onSelectedProductFor"
                          :limit="10"
                          @input="onInputChangeProductFor"
                          :input-props="{
                            id: 'autosuggest__input',
                            placeholder: 'Nhập tên nhóm sản phẩm',
                            style: 'border-radius:0px!important',
                          }"
                          :should-render-suggestions="
                            (size, loading) => size >= 0 && !loading
                          "
                        >
                          <template slot-scope="{ suggestion }">
                            <div>{{ suggestion.item.name }}</div>
                          </template>
                        </vue-autosuggest>
                      </b-col>
                      <b-col md="2"></b-col>
                      <b-col md="2"></b-col>
                      <b-col md="2" class="pr-0">
                        <div class="d-flex justify-content-end">
                          <button
                            type="button"
                            class="btn btn-secondary font-weight-bolder btn-sm"
                            @click="showModalImportProduct"
                          >
                            <i
                              style="font-size: 1rem"
                              class="far fa-file-excel"
                            ></i
                            >Nhập nhóm SP từ excel
                          </button>
                        </div>
                      </b-col>
                    </b-col>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-col cols="12" md="12">
                    <div>
                      <b-table
                        class="table-bordered table-hover col-md-12"
                        :fields="fields"
                        :items="mainModel.details"
                        v-if="renderComponent"
                      >
                        <template v-slot:cell(grossProfitTarget)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.grossProfitTarget"
                            v-mask="mask"
                          ></b-input>
                        </template>

                        <template v-slot:cell(incomeTarget)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.incomeTarget"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(inchargeEmployeeRewardRate)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.inchargeEmployeeRewardRate"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(adminRewardRate)="row">
                          <b-input
                            type="text"
                            size="sm"
                            class="input-style text-right"
                            v-model="row.item.adminRewardRate"
                            v-mask="mask"
                          ></b-input>
                        </template>
                        <template v-slot:cell(actions)="row">
                          <div class="d-flex justify-content-center">
                            <b-dropdown size="sm" id="dropdown-left" no-caret right>
                              <template slot="button-content">
                                <i
                                  style="font-size: 1rem; padding-right: 0px"
                                  class="flaticon2-settings"
                                ></i>
                              </template>
                              <b-dropdown-item
                                @click="showModalEmployeeRewardDetail(row.item)"
                              >
                                <span style="color: #3f4254; font-size: 12px">
                                  <i style="font-size: 1rem" class="flaticon2-pen"></i>
                                  &nbsp; Chỉnh sửa
                                </span>
                              </b-dropdown-item>
                              <b-dropdown-item
                                @click="showDeleteAlert(row.item)"
                              >
                                <span style="color: #3f4254; font-size: 12px">
                                  <i
                                    style="font-size: 1rem; color: #d33"
                                    class="flaticon2-rubbish-bin-delete-button"
                                  ></i>
                                  &nbsp; Xóa
                                </span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </template>
                      </b-table>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4"></b-col>
                </b-row>
              </b-container>
            </b-form>
            <b-modal
              ref="import-product-modal"
              hide-footer
              title="Thêm sản phẩm từ Excel"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-row>
                <b-col>
                  <div class="form-group">
                    <span>
                      <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                      đúng format, hoặc có thể tải ở
                      <a :href="urlExcel">đây</a>
                    </span>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <span> <b>Bước 2:</b> Tiến hành Import </span>
                  <div style="padding-top: 15px">
                    <b-form-file
                      placeholder="Hãy chọn một tập tin"
                      @change="selectFile"
                      v-model="file"
                      ref="file-input"
                      class="mb-2"
                    ></b-form-file>
                  </div>
                </b-col>
              </b-row>
              <b-container
                class="mt-4 pl-0 pr-0"
                v-show="importItems.length > 0"
              >
                <h5>
                  Danh sách sản phẩm từ file excel( Tổng:
                  {{ importItems.length }} nhóm sản phẩm)
                </h5>
                <b-table
                  id="my-table"
                  class="myTable"
                  responsive
                  bordered
                  hover
                  :fields="fieldImport"
                  :items="importItems"
                  :per-page="10"
                  :current-page="currentPage"
                >
                  <template v-slot:cell(billConfig)="row">
                    <p class="text-center">
                      {{row.item.billAmount1}}
                      <span v-if="(![1, 2, 3, 4, 5].includes(row.item.billAmountOperator1) || row.item.billAmountOperator1 === 1)">--</span>
                      <span v-if="row.item.billAmountOperator1 === 2">&lt;</span>
                      <span v-else-if="row.item.billAmountOperator1 === 3">&gt;</span>
                      <span v-else-if="row.item.billAmountOperator1 === 4">&le;</span>
                      <span v-else-if="row.item.billAmountOperator1 === 5">&ge;</span>
                    </p>
                    <p class="text-center">và</p>
                    <p class="text-center">
                      <span v-if="(![1, 2, 3, 4, 5].includes(row.item.billAmountOperator2) || row.item.billAmountOperator2 === 1)">--</span>
                      <span v-if="row.item.billAmountOperator2 === 2">&lt;</span>
                      <span v-else-if="row.item.billAmountOperator2 === 3">&gt;</span>
                      <span v-if="row.item.billAmountOperator2 === 4">&le;</span>
                      <span v-else-if="row.item.billAmountOperator2 === 5">&ge;</span>
                      {{row.item.billAmount2}}
                    </p>
                  </template>
                </b-table>
                <b-pagination
                  v-show="importItems.length > 10"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="10"
                  aria-controls="my-table"
                ></b-pagination>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="validData"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalImportProduct"
                >Hủy</b-button
              >
            </b-modal>
            <b-modal
              ref="reward-rate-modal"
              hide-footer
              title="Mức thưởng nhân viên"
              id="modal-prevent-closing"
            >
              <b-row>
                <b-col md="3">
                  <span style="font-weight: 500">Tên nhóm:</span>
                </b-col>
                <b-col>
                  <span>{{ rewardProCateName }}</span>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col md="8">
                  <vue-autosuggest
                    v-model="searchJobTitle"
                    :suggestions="filteredOptionsJobTitle"
                    @selected="onSelectedJobTitle"
                    :limit="10"
                    @input="onInputChangeJobTitle"
                    :input-props="{
                      id: 'autosuggest__input',
                      placeholder: 'Nhập tên chức vụ',
                      style: 'border-radius:0px!important',
                    }"
                  >
                    <template slot-scope="{ suggestion }">
                      <div>{{ suggestion.item.name }}</div>
                    </template>
                  </vue-autosuggest>
                </b-col>

                <b-col md="4">
                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-secondary font-weight-bolder btn-sm"
                      @click="visibleSearch = !visibleSearch"
                    >
                      <i style="font-size: 1rem" class="far fa-file-excel"></i
                      >Nhập từ excel
                    </button>
                  </div>
                </b-col>
              </b-row>
              <b-collapse id="collapse-4" v-model="visibleSearch" class="mb-5">
                <b-row>
                  <b-col>
                    <div style="padding-top: 15px">
                      <span>
                        <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file
                        excel đúng format, hoặc có thể tải ở
                        <a :href="urlExcelER">đây</a>
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <span> <b>Bước 2:</b> Tiến hành Import </span>
                    <div>
                      <b-form-file
                        placeholder="Hãy chọn một tập tin"
                        @change="selectFileER"
                        v-model="fileER"
                        ref="file-input"
                        class="mb-2"
                      ></b-form-file>
                    </div>
                  </b-col>
                </b-row>
              </b-collapse>
              <b-container class="mt-4 pl-0 pr-0">
                <b-table
                  class="table-bordered table-hover col-md-12"
                  :fields="fieldRewardRate"
                  :items="listRewardRate"
                  v-if="renderComponent"
                >
                  <template v-slot:cell(point)="row">
                    <b-input
                      type="text"
                      size="sm"
                      class="input-style text-right"
                      v-model="row.item.point"
                      v-mask="mask"
                    ></b-input>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <v-icon
                      small
                      class="text-danger"
                      @click="deleteInventoryTimeTarget(row.item)"
                      v-b-tooltip
                      title="Xóa"
                      >mdi-delete</v-icon
                    >
                  </template>
                </b-table>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="saveListEmployeeReward"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideRewardRateModal"
                >Hủy</b-button
              >
            </b-modal>
            <b-modal
              ref="employee-reward-detail-modal"
              hide-footer
              title="Cài đặt chi tiết hoa hồng nhân viên"
              id="modal-prevent-closing"
              size="lg"
            >
              <b-container class="mt-4 pl-0 pr-0">
                <h5>{{ detailEmployeeReward.name }}</h5>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      id="input-3"
                      label="Mục tiêu lãi gộp(%):"
                    >
                      <b-form-input
                        size="sm"
                        id="input-detailEmployeeReward-grossProfitTarget"
                        type="text"
                        v-model="detailEmployeeReward.grossProfitTarget"
                        placeholder="Nhập mục tiêu lãi gộp(%)"
                        class="text-right col-6 pl-0"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      id="input-3"
                      label="Mục tiêu DT(VND):"
                    >
                      <b-form-input
                        size="sm"
                        id="input-detailEmployeeReward-incomeTarget"
                        type="text"
                        v-model="detailEmployeeReward.incomeTarget"
                        placeholder="Nhập mục tiêu DT(VND)"
                        class="text-right col-6 pl-0"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      id="input-3"
                      label="NV phụ trách:"
                    >
                      <vue-autosuggest
                        v-model="detailEmployeeReward.inchargeEmployeeName"
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedInchargeEmployee"
                        :limit="10"
                        @input="onInputChangeInchargeEmployee"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập NV phụ trách',
                          style: 'border-radius:0px!important',
                        }"
                      >
                        <template slot-scope="{ suggestion }">
                          <div>{{ suggestion.item.name }}</div>
                        </template>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="input-3"
                      label="Tỷ lệ thưởng/ gói thưởng (%):"
                    >
                      <b-form-input
                        size="sm"
                        id="input-detailEmployeeReward-inchargeEmployeeRewardRate"
                        type="text"
                        v-model="detailEmployeeReward.inchargeEmployeeRewardRate"
                        placeholder="Nhập tỷ lệ thưởng/ gói thưởng (%)"
                        class="text-right col-12 pl-0"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      id="input-3"
                      label="Admin:"
                    >
                      <vue-autosuggest
                        v-model="detailEmployeeReward.adminName"
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedAdmin"
                        :limit="10"
                        @input="onInputChangeAdmin"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập NV Admin',
                          style: 'border-radius:0px!important',
                        }"
                      >
                        <template slot-scope="{ suggestion }">
                          <div>{{ suggestion.item.name }}</div>
                        </template>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      id="input-3"
                      label="Tỷ lệ thưởng/ gói thưởng (%):"
                    >
                      <b-form-input
                        size="sm"
                        id="input-rewardPackageTarget"
                        type="text"
                        v-model="detailEmployeeReward.adminRewardRate"
                        placeholder="Nhập tỷ lệ thưởng/ gói thưởng (%)"
                        class="text-right col-12 pl-0"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <label>Doanh thu hoá đơn:</label>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      id="input-3"
                      label="Giá trị 1"
                    >
                      <b-form-input
                        size="sm"
                        id="input-detailEmployeeReward-billAmount1"
                        type="text"
                        v-model="detailEmployeeReward.billAmount1"
                        placeholder="Nhập DT 1"
                        class="text-right col-12 pl-0"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="input-3"
                      label="Toán tử 1"
                    >
                      <b-form-select
                        v-model="detailEmployeeReward.billAmountOperator1"
                        size="sm"
                      >
                        <b-form-select-option value="1" class="text-center">
                          --
                        </b-form-select-option>
                        <b-form-select-option value="2">
                          &lt;
                        </b-form-select-option>
                        <b-form-select-option value="4">
                          &le;
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="2" class="text-center">
                    <b-row>
                      <b-col md="12">
                        -
                      </b-col>
                      <b-col md="12">
                        DT Hoá Đơn
                      </b-col>
                      <b-col md="12">
                        -
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="input-3"
                      label="Toán tử 2"
                    >
                      <b-form-select
                        v-model="detailEmployeeReward.billAmountOperator2"
                        size="sm"
                      >
                        <b-form-select-option value="1" class="text-center">
                          --
                        </b-form-select-option>
                        <b-form-select-option value="2">
                          &lt;
                        </b-form-select-option>
                        <b-form-select-option value="4">
                          &le;
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      id="input-3"
                      label="Giá trị 2"
                    >
                      <b-form-input
                        size="sm"
                        id="input-detailEmployeeReward-billAmount2"
                        type="text"
                        v-model="detailEmployeeReward.billAmount2"
                        placeholder="Nhập DT 2"
                        class="text-right col-12 pl-0"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="saveEmployeeRewardDetail"
                >Lưu</b-button
              >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                @click="hideModalEmployeeRewardDetail"
                >Hủy</b-button
              >
            </b-modal>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <b-container class="bv-example-row">
              <b-row>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  type="submit"
                  @click="onSubmit"
                  >Lưu</b-button
                >
                <!-- </b-col> -->
                <!-- <b-col lg="2" class="pb-2"> -->
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="onBack"
                  >Hủy</b-button
                >
                <b-col></b-col>
                <b-col></b-col>
                <b-col></b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import { removeAccents } from './../../../utils/common';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { getToastInstance } from './../../../utils/toastHelper';
import _ from 'lodash';
import { currencyMask, unMaskPrice, convertAmountOperator } from './../../../utils/common';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_REWARD_ACCESSORY_PROGRAM,
  URL_IMPORT_EMPLOYEE_REWARD,
} from './../../../utils/constants';
import xlsx from 'xlsx';
import { v4 } from 'uuid';
import { xoa_dau } from './../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      selectedCategory: [],
      selectedStores: [],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      mainModel: {
        id: 0,
        name: null,
        description: null,
        startAt: new Date(),
        endAt: null,
        details: [],
        status: true,
        rewardPackageTarget: 0,
        grossProfitTarget: 0,
        incomeTarget: 0,
        inventoryValueTarget: 0,
        inventoryValuePoint: 0,
        inventoryTimePoint: 0,
        listInventoryTimeTarget: [],
        directorId: null,
        directorRewardRate: 0,
        directorAdminId: null,
        directorAdminRewardRate: 0,
      },
      detailEmployeeReward: {
        id: null,
        name: null,
        grossProfitTarget: 0,
        incomeTarget: 0,
        inchargeEmployeeName: null,
        inchargeEmployeeRewardRate: 0,
        adminId: null,
        adminName: null,
        adminRewardRate: 0,
        billAmount1: 0,
        billAmountOperator1: undefined,
        billAmount2: 0,
        billAmountOperator2: undefined,
        count: null,
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'grossProfitTarget',
          label: 'Mục tiêu lãi gộp(%)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'incomeTarget',
          label: 'Mục tiêu DT(VND)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      listProductFor: [],
      filteredOptionsProductFor: [],
      optionsProductFor: [
        {
          data: [],
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProductAccessory: '',
      accessoryItems: [],
      accessoryProductItems: [],
      searchProductFor: '',
      categories: '',
      stores: '',
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Không hoạt động',
        },
      ],
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      isSearching: false,
      importItems: [],
      file: null,
      fileER: null,
      excellist: [],
      excellistER: [],
      fieldImport: [
        {
          key: 'name',
          label: 'Tên nhóm',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'grossProfitTarget',
          label: 'Mục tiêu lãi gộp(%)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'incomeTarget',
          label: 'Mục tiêu DT(VND)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'inchargeEmployeeName',
          label: 'NV phụ trách',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'inchargeEmployeeRewardRate',
          label: 'Tỷ lệ thưởng/ gói thưởng (%)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'adminName',
          label: 'Admin',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'adminRewardRate',
          label: 'Tỷ lệ thưởng/ gói thưởng (%)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'billConfig',
          label: 'Doanh thu hoá đơn',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-right',
        },
      ],
      currentPage: 1,
      urlExcel: URL_IMPORT_REWARD_ACCESSORY_PROGRAM,
      filteredOptionsJobTitle: [],
      optionsJobTitle: [
        {
          data: [
            { id: 1, name: 'Giám đốc' },
            { id: 2, name: 'Quản lý vùng' },
            { id: 3, name: 'Quản lý khu vực' },
            { id: 4, name: 'Quản lý cửa hàng' },
            { id: 5, name: 'Trợ lý cửa hàng' },
            { id: 6, name: 'Kế toán bán hàng' },
            { id: 7, name: 'Tư vấn bán hàng' },
            { id: 8, name: 'Hỗ trợ kỹ thuật' },
            { id: 9, name: 'Tiếp đón khách hàng' },
            { id: 10, name: 'Kỹ thuật phần cứng' },
          ],
        },
      ],
      fieldRewardRate: [
        {
          key: 'name',
          label: 'Chức vụ',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '50%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'point',
          label: 'Điểm thưởng',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '40%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '10%',
          },
        },
      ],
      listRewardRate: [],
      rewardProCateName: '',
      rewardProCateId: null,
      listJobTitle: [
        { id: 1, name: 'Giám đốc' },
        { id: 2, name: 'Quản lý vùng' },
        { id: 3, name: 'Quản lý khu vực' },
        { id: 4, name: 'Quản lý cửa hàng' },
        { id: 5, name: 'Trợ lý cửa hàng' },
        { id: 6, name: 'Kế toán bán hàng' },
        { id: 7, name: 'Tư vấn bán hàng' },
        { id: 8, name: 'Hỗ trợ kỹ thuật' },
        { id: 9, name: 'Tiếp đón khách hàng' },
        { id: 10, name: 'Kỹ thuật phần cứng' },
      ],
      searchJobTitle: '',
      visibleSearch: false,
      urlExcelER: URL_IMPORT_EMPLOYEE_REWARD,
      fieldInventoryTimeTarget: [
        {
          key: 'priority',
          label: 'Thứ tự',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-center w-10',
        },
        {
          key: 'fromMonth',
          label: 'Từ( tháng)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-center w-10',
        },
        {
          key: 'toMonth',
          label: 'Đến( tháng)',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '15%',
          },
          tdClass: 'text-center w-10',
        },
        {
          key: 'isCheckInventoryValue',
          label: 'KT tồn kho',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-center w-20',
        },
        {
          key: 'rateOnIncome',
          label: 'Tỷ lệ DTTB',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-center w-40',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
          tdClass: 'text-center',
        },
      ],
      searchDirector: '',
      searchDirectorAdmin: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      inchargeEmployeeId: null,
      adminId: null,
      currentindex: 0,
    };
  },
  validations: {
    mainModel: {
      startAt: {
        required,
        minValue: minValue(0),
      },
      name: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Chương trình thưởng hoa hồng ngành hàng phụ kiện',
        route: '/reward/reward-accessory-program',
      },
      {
        title: 'Thêm mới chương trình tính hoa hồng ngành hàng phụ kiện',
      },
    ]);
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
  },
  created() {
    this.getListEmployee();
    this.rewardProgramId = this.$route.query.id;
    this.getRewardProgramById();
  },
  methods: {
    ...getToastInstance(),
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    updateItem(item) {
      this.accessoryItems.forEach((element, index) => {
        if (element.productId === item.productId) {
          this.accessoryItems[index].importPrice = item.importPrice;
        }
      });
    },
    formatMoney(number) {
      const money = new Intl.NumberFormat('vn-VN').format(number);
      return _.isNaN(money) ? 0 : money;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.mainModel.details = _.reject(
            this.mainModel.details,
            (product) => {
              return product.count == item.count;
            }
          );
          this.mainModel.details.forEach((element, index) => {
            element.count = index + 1;
          });
        }
      });
    },
    deleteInventoryTimeTarget: function (item) {
      this.mainModel.listInventoryTimeTarget = _.reject(
        this.mainModel.listInventoryTimeTarget,
        (element) => {
          return element.priority == item.priority;
        }
      );
    },
    sortById(a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    },
    deleteItemInArray(productId) {
      for (var i = 0; i < this.accessoryItems.length; i++) {
        if (this.accessoryItems[i].productId === productId) {
          this.accessoryItems.splice(i, 1);
        }
      }
    },
    fetchProductFor(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProductFor[0].data = [];
      ApiService.setHeader();
      ApiService.get(`rewardProductCategory/search?name=${textSearch}`).then(
        ({ data }) => {
          const products = data.data;
          this.listProduct = products;
          products.map((element) => {
            this.optionsProductFor[0].data.push(element);
          });
          this.filteredOptionsProductFor = [
            {
              data: this.optionsProductFor[0].data,
            },
          ];
          this.isSearching = false;
          if (textSearch !== this.searchProductFor) {
            let nextTextSearch = this.searchProductFor;
            this.fetchProductFor(nextTextSearch);
          }
        }
      );
    },
    newId() {
      return v4();
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';

      let item = {
        id: this.newId(),
        rewardProgramCategoryId: option.item.id,
        name: option.item.name,
        grossProfitTarget: 0,
        incomeTarget: 0,
        inchargeEmployeeId: null,
        inchargeEmployeeRewardRate: 0,
        adminId: null,
        adminRewardRate: 0,
        adminName: '',
        inchargeEmployeeName: '',
      };
      this.mainModel.details.push(item);
      this.mainModel.details.forEach((element, index) => {
        element.count = index + 1;
      });
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    getListEmployee() {
      ApiService.setHeader();
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          let item = {
            id: element.id,
            name: element.fullName,
          };
          this.optionsEmployee[0].data.push(item);
        });
      });
    },
    onSelectedJobTitle(option) {
      let item = {
        jobTitleId: option.item.id,
        name: option.item.name,
        point: 0,
      };
      this.listRewardRate.push(item);
      this.optionsJobTitle[0].data = _.reject(
        this.optionsJobTitle[0].data,
        (item) => {
          return item.id == option.item.id;
        }
      );
      this.filteredOptionsJobTitle = [
        {
          data: this.optionsJobTitle[0].data,
        },
      ];
      this.searchJobTitle = '';
    },
    onInputChangeJobTitle(text) {
      if (!text) {
        text = '';
      }
      this.searchJobTitle = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsJobTitle[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, 10);

      this.filteredOptionsJobTitle = [
        {
          data: filteredData,
        },
      ];
    },
    onBack: function () {
      history.back();
    },
    onSubmit: function () {
      //format unmask price
      this.mainModel.details.forEach((element) => {
        element.incomeTarget = unMaskPrice(element.incomeTarget);
        element.inchargeEmployeeRewardRate = unMaskPrice(
          element.inchargeEmployeeRewardRate
        );
        element.adminRewardRate = unMaskPrice(element.adminRewardRate);
        element.billAmount1 = element.billAmount1 ? unMaskPrice(element.billAmount1) : undefined;
        element.billAmount2 = element.billAmount2 ? unMaskPrice(element.billAmount2) : undefined;
      });
      this.mainModel.listInventoryTimeTarget.forEach((element) => {
        element.rateOnIncome = unMaskPrice(element.rateOnIncome);
      });
      this.mainModel.startAt = moment(
        this.mainModel.startAt,
        'DD/MM/yyyy'
      ).toDate();
      this.$v.mainModel.$touch();
      if (this.$v.mainModel.$anyError) {
        return;
      }
      this.mainModel.startAt = this.mainModel.startAt
        ? moment(this.mainModel.startAt, 'DD/MM/YYYY').valueOf()
        : null;
      this.mainModel.endAt = this.mainModel.endAt
        ? moment(this.mainModel.endAt, 'DD/MM/YYYY').valueOf()
        : null;
      let data = {
        name: this.mainModel.name,
        description: this.mainModel.description,
        status: this.mainModel.status,
        startDate: this.mainModel.startAt,
        endDate: this.mainModel.endAt,
        listItem: this.mainModel.details,
        rewardPackageTarget: unMaskPrice(this.mainModel.rewardPackageTarget),
        grossProfitTarget: unMaskPrice(this.mainModel.grossProfitTarget),
        incomeTarget: unMaskPrice(this.mainModel.incomeTarget),
        inventoryValueTarget: unMaskPrice(this.mainModel.inventoryValueTarget),
        inventoryValuePoint: unMaskPrice(this.mainModel.inventoryValuePoint),
        inventoryTimePoint: unMaskPrice(this.mainModel.inventoryTimePoint),
        listInventoryTimeTarget: this.mainModel.listInventoryTimeTarget,
        directorRewardRate: unMaskPrice(this.mainModel.directorRewardRate),
        directorAdminRewardRate: unMaskPrice(this.mainModel.directorAdminRewardRate),
        directorId: this.mainModel.directorId,
        directorAdminId: this.mainModel.directorAdminId,
      };
      ApiService.post('rewardProgram/reward-accessory-program', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            //this.$router.go(-1);
            history.back();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    debounceInputFor: decounce(function () {
      let textSearch = this.searchProductFor;
      if (!this.isSearching) {
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    convertOperatorType(textOperator) {
      let result = 0;
      switch (textOperator) {
        case '<':
          result = 1;
          break;
        case '>':
          result = 2;
          break;
        case '<=':
          result = 3;
          break;
        case '>=':
          result = 4;
          break;
      }
      return result;
    },
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              name: element['Tên nhóm'] ? element['Tên nhóm'] : '',
              grossProfitTarget: element['Mục tiêu lãi gộp(%)']
                ? element['Mục tiêu lãi gộp(%)']
                : 0,
              incomeTarget: element['Mục tiêu DT(VND)']
                ? element['Mục tiêu DT(VND)']
                : 0,
              inchargeEmployeeName: element['NV phụ trách']
                ? element['NV phụ trách']
                : '',
              adminName: element['Admin'] ? element['Admin'] : '',
              inchargeEmployeeRewardRate: element[
                'Tỷ lệ thưởng/ gói thưởng (%) NV'
              ]
                ? element['Tỷ lệ thưởng/ gói thưởng (%) NV']
                : 0,
              adminRewardRate: element['Tỷ lệ thưởng/ gói thưởng (%) Admin']
                ? element['Tỷ lệ thưởng/ gói thưởng (%) Admin']
                : 0,
              billAmount1: element['Doanh thu hoá đơn (VND)'] ? element['Doanh thu hoá đơn (VND)'] : 0 ,
              billAmountOperator1: element['__EMPTY'] ? convertAmountOperator(element['__EMPTY']) : 1,
              billAmount2: element['__EMPTY_3'] ? element['__EMPTY_3'] : 0,
              billAmountOperator2: element['__EMPTY_2'] ? convertAmountOperator(element['__EMPTY_2']) : 1
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    getJobTitleIdByName(name) {
      let result = null;
      switch (name) {
        case 'Giám đốc':
          result = 1;
          break;
        case 'Quản lý vùng':
          result = 2;
          break;
        case 'Quản lý khu vực':
          result = 3;
          break;
        case 'Quản lý cửa hàng':
          result = 4;
          break;
        case 'Trợ lý cửa hàng':
          result = 5;
          break;
        case 'Kế toán bán hàng':
          result = 6;
          break;
        case 'Tư vấn bán hàng':
          result = 7;
          break;
        case 'Kỹ thuật phần mềm':
          result = 8;
          break;
        case 'Tiếp đón khách hàng':
          result = 9;
          break;
      }
      return result;
    },
    selectFileER(event) {
      const files = event.target.files[0];
      this.fileER = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.fileER = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellistER = excellist;
          this.excellistER.map((element) => {
            let data = {
              name: element['Chức vụ'] ? element['Chức vụ'] : '',
              jobTitleId: this.getJobTitleIdByName(element['Chức vụ']),
              point: element['Điểm thưởng'] ? element['Điểm thưởng'] : 0,
            };
            this.listRewardRate.push(data);
            this.optionsJobTitle[0].data = _.reject(
              this.optionsJobTitle[0].data,
              (item) => {
                return item.id == data.jobTitleId;
              }
            );
            this.filteredOptionsJobTitle = [
              {
                data: this.optionsJobTitle[0].data,
              },
            ];
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      const listProduct = this.importItems.map((importItem) => {
        if(importItem.billAmountOperator1 && ![1, 2, 4].includes(importItem.billAmountOperator1)) {
          return this.makeToastFailure(`Danh mục: ${importItem.name}. Toán tử 1 (--, <, <=)`);
        }
        if(importItem.billAmountOperator1 && ![1, 2, 4].includes(importItem.billAmountOperator1)) {
          return this.makeToastFailure(`Danh mục: ${importItem.name}. Toán tử 2 (--, <, <=)`);
        }
        if (
          (importItem.billAmount1 && (!importItem.billAmountOperator1 || importItem.billAmountOperator1 == 1)) ||
          (!importItem.billAmount1 && !(!importItem.billAmountOperator1 || importItem.billAmountOperator1 == 1))
        ) {
          const inputError = !importItem.billAmount1 ? 'Giá trị 1' : 'Toán tử 1';
          return this.makeToastFailure(`Danh mục: ${importItem.name}. Nhập ${inputError}`);
        }
        if (
          (importItem.billAmount2 && (!importItem.billAmountOperator2 || importItem.billAmountOperator2 == 1)) ||
          (!importItem.billAmount2 && !(!importItem.billAmountOperator2 || importItem.billAmountOperator2 == 1))
        ) {
          const inputError = !importItem.billAmount2 ? 'Giá trị 2' : 'Toán tử 2';
          return this.makeToastFailure(`Danh mục: ${importItem.name}. Nhập ${inputError}`);
        }
        if (
          importItem.billAmount1 && 
          importItem.billAmount2 && 
          Number(unMaskPrice(importItem.billAmount1)) > Number(unMaskPrice(importItem.billAmount2))
        ) {
          return this.makeToastFailure(`Danh mục: ${importItem.name}. Giá trị 1 phải nhỏ hơn Giá trị 2.`);
        }
        return importItem;
      })

      if (listProduct.length === 0) {
        return;
      }

      const data = {
        listProduct,
      };
      ApiService.setHeader();
      ApiService.post('rewardProgram/valid-import-rcp', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.mainModel.details.push(element);
            });
            this.mainModel.details.forEach((element, index) => {
              element.count = index + 1;
            });
            this.hideModalImportProduct();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err;
          this.makeToastFailure(message);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailure(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    showRewardRateModal(item) {
      this.optionsJobTitle[0].data = [];
      this.listJobTitle.forEach((element) => {
        this.optionsJobTitle[0].data.push(element);
      });
      let tmp = [{ data: this.optionsJobTitle[0].data }];
      this.filteredOptionsJobTitle = tmp;
      this.rewardProCateName = item.name;
      this.rewardProCateId = item.id;
      this.listRewardRate = item.listEmployeeReward;
      this.$refs['reward-rate-modal'].show();
    },
    hideRewardRateModal() {
      this.$refs['reward-rate-modal'].hide();
    },
    saveListEmployeeReward() {
      this.mainModel.details.forEach((element, index) => {
        if (element.id === this.rewardProCateId) {
          this.mainModel.details[
            index
          ].listEmployeeReward = this.listRewardRate;
        }
      });
      this.hideRewardRateModal();
    },
    showModalEmployeeRewardDetail(item) {
      this.detailEmployeeReward = {
        id: item.id,
        name: item.name,
        grossProfitTarget: item.grossProfitTarget,
        incomeTarget: item.incomeTarget,
        inchargeEmployeeId: item.inchargeEmployeeId,
        inchargeEmployeeName: item.inchargeEmployeeName,
        inchargeEmployeeRewardRate: item.inchargeEmployeeRewardRate,
        adminId: item.adminId,
        adminName: item.adminName,
        adminRewardRate: item.adminRewardRate,
        billAmount1: item.billAmount1,
        billAmountOperator1: item.billAmountOperator1,
        billAmount2: item.billAmount2,
        billAmountOperator2: item.billAmountOperator2,
        rewardProgramCategoryId: item.rewardProgramCategoryId,
        count: item.count,
      };
      
      this.currentindex = this.mainModel.details.findIndex(
        (detail) => detail.count == item.count
      );
      this.$refs['employee-reward-detail-modal'].show();
    },
    hideModalEmployeeRewardDetail() {
      this.$refs['employee-reward-detail-modal'].hide();
    },
    saveEmployeeRewardDetail() {
      if (
        (this.detailEmployeeReward.billAmount1 && (!this.detailEmployeeReward.billAmountOperator1 || this.detailEmployeeReward.billAmountOperator1 == 1)) ||
        (!this.detailEmployeeReward.billAmount1 && !(!this.detailEmployeeReward.billAmountOperator1 || this.detailEmployeeReward.billAmountOperator1 == 1))
      ) {
        const inputError = !this.detailEmployeeReward.billAmount1 ? 'Giá trị 1' : 'Toán tử 1';
        return this.makeToastFailure(`Doanh thu hoá đơn. Nhập ${inputError}`);
      }
      if (
        (this.detailEmployeeReward.billAmount2 && (!this.detailEmployeeReward.billAmountOperator2 || this.detailEmployeeReward.billAmountOperator2 == 1)) ||
        (!this.detailEmployeeReward.billAmount2 && !(!this.detailEmployeeReward.billAmountOperator2 || this.detailEmployeeReward.billAmountOperator2 == 1))
      ) {
        const inputError = !this.detailEmployeeReward.billAmount2 ? 'Giá trị 2' : 'Toán tử 2';
        return this.makeToastFailure(`Doanh thu hoá đơn. Nhập ${inputError}`);
      }
      if (
        this.detailEmployeeReward.billAmount1 && 
        this.detailEmployeeReward.billAmount2 && 
        Number(unMaskPrice(this.detailEmployeeReward.billAmount1)) > Number(unMaskPrice(this.detailEmployeeReward.billAmount2))
      ) {
        return this.makeToastFailure(`Doanh thu hoá đơn. Giá trị 1 phải nhỏ hơn Giá trị 2.`);
      }

      this.mainModel.details.forEach((element) => {
        if (element.count === this.detailEmployeeReward.count) {
          Object.assign(element, this.detailEmployeeReward);
        }
      });

      this.hideModalEmployeeRewardDetail();
    },
    sortByPriority(a, b) {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return 0;
    },
    onAddCondition() {
      this.mainModel.listInventoryTimeTarget.sort(this.sortByPriority);
      let priority =
        this.mainModel.listInventoryTimeTarget.length > 0
          ? this.mainModel.listInventoryTimeTarget[
              this.mainModel.listInventoryTimeTarget.length - 1
            ].priority + 1
          : 1;
      this.mainModel.listInventoryTimeTarget.push({
        priority: priority,
        fromMonth: 0,
        toMonth: 0,
        isCheckInventoryValue: false,
        rateOnIncome: 0,
      });
    },
    onInputChangeDirector(text) {
      if (!text) {
        text = '';
      }
      this.searchDirector = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedDirector(option) {
      this.searchDirector = option.item.name;
      this.mainModel.directorId = option.item.id;
    },
    onInputChangeDirectorAdmin(text) {
      if (!text) {
        text = '';
      }
      this.searchDirectorAdmin = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedDirectorAdmin(option) {
      this.searchDirectorAdmin = option.item.name;
      this.mainModel.directorAdminId = option.item.id;
    },
    onInputChangeInchargeEmployee(text) {
      if (!text) {
        text = '';
      }
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedInchargeEmployee(option) {
      this.inchargeEmployeeId = option.item.id;
      this.detailEmployeeReward.inchargeEmployeeId = option.item.id;
      this.detailEmployeeReward.inchargeEmployeeName = option.item.name;
    },
    onInputChangeAdmin(text) {
      if (!text) {
        text = '';
      }
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item.name);
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    onSelectedAdmin(option) {
      this.adminId = option.item.id;
      this.detailEmployeeReward.adminId = option.item.id;
      this.detailEmployeeReward.adminName = option.item.name;
    },
    onChangeEmp(selectedItem) {
      this.currentindex = this.mainModel.details.findIndex(
        (item) => item.count == selectedItem.count
      );
    },
    onChangeAdmin(selectedItem) {
      this.currentindex = this.mainModel.details.findIndex(
        (item) => item.count == selectedItem.count
      );
    },
    getRewardProgramById: function () {
      ApiService.setHeader();
      ApiService.get(
        'rewardProgram/reward-accessory-program/' + this.rewardProgramId
      ).then((res) => {
        let {
          name,
          description,
          startDate,
          endDate,
          isActive,
          listItem,
          directorId,
          directorName,
          directorRewardRate,
          directorAdminId,
          directorAdminName,
          directorAdminRewardRate,
          rewardPackageTarget,
          grossProfitTarget,
          incomeTarget,
          inventoryValueTarget,
          inventoryValuePoint,
          inventoryTimePoint,
          listInventoryTimeTarget,
          rewardAccessoryId,
        } = res.data.data;
        this.mainModel.name = name;
        this.mainModel.description = description;
        this.mainModel.startAt =
          startDate !== null ? moment(startDate).format('DD/MM/YYYY') : '';
        this.mainModel.endAt =
          endDate !== null ? moment(endDate).format('DD/MM/YYYY') : '';
        this.mainModel.status = isActive === 1 ? true : false;
        this.mainModel.details = listItem;
        this.mainModel.details.forEach((element, index) => {
          element.count = index + 1;
        });
        // save original data
        this.originalModel = JSON.parse(JSON.stringify(listItem));
        this.oldStatus = isActive === 1 ? true : false;
        this.mainModel.directorId = directorId;
        this.searchDirector = directorName;
        this.mainModel.directorRewardRate = directorRewardRate;
        this.mainModel.directorAdminId = directorAdminId;
        this.searchDirectorAdmin = directorAdminName;
        this.mainModel.directorAdminRewardRate = directorAdminRewardRate;
        this.mainModel.rewardPackageTarget = rewardPackageTarget;
        this.mainModel.grossProfitTarget = grossProfitTarget;
        this.mainModel.incomeTarget = incomeTarget;
        this.mainModel.inventoryValueTarget = inventoryValueTarget;
        this.mainModel.inventoryValuePoint = inventoryValuePoint;
        this.mainModel.inventoryTimePoint = inventoryTimePoint;
        this.mainModel.listInventoryTimeTarget = listInventoryTimeTarget;
        this.mainModel.rewardAccessoryId = rewardAccessoryId;
      });
      //this.mainModel.inventoryValueTarget = 100;
    },
  },
};
</script>

<style>
.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 500;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.productCode:hover {
  text-decoration: underline;
}
.row-center-vertical {
  display: flex;
  align-items: center;
}
</style>
